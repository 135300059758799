<template>
  <div class="data-details__date-item">
    <p v-show="displayDesc && date.description" class="mb-1">{{ date.description }}:</p>
    <div class="d-flex">
      <div class="text-nowrap">{{ startDate }}</div>
      <div v-show="startDate !== endDate" class="text-nowrap ml-1">- {{ endDate }}</div>
    </div>
    <div v-if="displayTime" class="d-flex">
      <div class="text-nowrap">{{ startTime }}</div>
      <div v-show="startTime !== endTime" class="text-nowrap ml-1">- {{ endTime }}</div>
    </div>
  </div>
</template>

<script>
import { formatDate, formatTime } from '@/helpers/dateTimeHelper';

export default {
  name: 'DataItemInfoDateItem',
  props: {
    date: {
      type: Object,
      required: true,
    },
    displayDesc: {
      type: Boolean,
      default: true,
    },
    displayTime: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    startDate() {
      return formatDate(this.date.start_date);
    },
    endDate() {
      return formatDate(this.date.end_date);
    },
    startTime() {
      return formatTime(this.date.start_date);
    },
    endTime() {
      return formatTime(this.date.end_date);
    },
  },
};
</script>
