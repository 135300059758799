<template>
  <div>
    <template v-if="item.start_time !== 'CLOSED'">{{ daysTitle }}: {{ timeDetails }}</template>
    <template v-else>{{ daysTitle }}: CLOSED</template>
  </div>
</template>

<script>
import { formatTime } from '@/helpers/dateTimeHelper';

export default {
  name: 'DataItemInfoOpeningHoursItem',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    daysTitle() {
      return this.item.day.replace('-', ' - ');
    },
    timeDetails() {
      const startTime = formatTime(this.item.start_time);
      const endTime = formatTime(this.item.end_time);

      return startTime !== endTime ? `${startTime} - ${endTime}` : startTime;
    },
  },
};
</script>
