<template>
  <MglMap
    class="map-with-entity-destination"
    :access-token="mapAccessToken"
    :center="[longitude, latitude]"
    :map-style="mapStyle"
    :zoom="zoom"
    v-on="$listeners"
  >
    <MglMarker :coordinates="[longitude, latitude]" :offset="[0, -14]">
      <div slot="marker" class="default-marker">
        <div class="default-marker__circle"></div>
        <div class="default-marker__circle"></div>
        <div class="default-marker__circle"></div>
      </div>
    </MglMarker>

    <slot />
  </MglMap>
</template>

<script>
import { MglMap, MglMarker } from 'vue-mapbox';
import { MAP_ACCESS_TOKEN, MAP_STYLE_URL } from '@/helpers/mapboxHelper';

export default {
  name: 'MapWithEntityDestination',
  components: {
    MglMap,
    MglMarker,
  },
  props: {
    latitude: {
      type: Number,
      default: 0,
    },
    longitude: {
      type: Number,
      default: 0,
    },
    zoom: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      mapAccessToken: MAP_ACCESS_TOKEN,
      mapStyle: MAP_STYLE_URL,
    };
  },
};
</script>

<style lang="scss" scoped>
@keyframes marker {
  from {
    width: 100%;
    height: 100%;
    opacity: 0.6;
  }
  to {
    width: 800%;
    height: 800%;
    opacity: 0;
  }
}

.default-marker {
  width: 34px;
  height: 34px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  border: 2px solid #fff;
  border-radius: 50%;

  &:before {
    content: 'C';
    font-family: 'FuturaPT', sans-serif;
    font-size: 10px;
    font-weight: 900;
    line-height: 1;
    color: #fff;
  }

  &__circle {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 1;
    border-radius: 50%;
    background: transparent;
    border: 1px solid #000;
    animation-name: marker;
    animation-iteration-count: infinite;
    animation-duration: 3s;
    animation-timing-function: linear;

    &:nth-child(2) {
      animation-delay: 1s;
    }
    &:nth-child(3) {
      animation-delay: 2s;
    }
  }
}
</style>
